<template>
  <div class="wx-pay" v-loading="loading">
    <div class="w-container">
      <div class="w-hd">
        <div class="h-title">
          <i
            class="school icon-saomiao"
            style="color: #06b259; margin-right: 10px"
          ></i>
          <span>微信支付</span>
        </div>
        <div class="h-money">
          充值金额:
          <strong>{{ rechargeValue }}</strong>
          元
        </div>
      </div>
      <div class="w-bd">
        <div class="b-qr">
          <div class="q-img">
            <div class="wrap" id="qrcode" ref="qrcode"></div>
          </div>
          <div class="q-txt">
            <div class="q-icon">
              <i class="iconfont icon-saoyisao"></i>
            </div>
            <ul class="q-content">
              <li>请使用微信扫一扫</li>
              <li>扫描二维码支付</li>
            </ul>
          </div>
        </div>
        <div class="b-img">
          <img src="../../../../../assets/images/phone.png" />
        </div>
      </div>
      <div @click="goBack()">
        <i class="fa fa-chevron-circle-left w-ft"></i>
        <span class="b-txt">返回</span>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    wxPay
  } from '@/api/account'
  import QRCode from "qrcodejs2"
import { mapState} from 'vuex'
  export default {
    name: "wxPay",
    data() {
      return {
        qrcode: "",
        // orderId: "",
        loading:false
      }
    },
    computed:{
        ...mapState(['rechargeValue'])
    },
    created() {
      this.qrcode = this.$route.query.code
      this.$nextTick(() => {
          this.crateQrcode()
        })

    },
    methods: {
      // 生成二维码
      crateQrcode() {
        new QRCode("qrcode", {
          width: 210,
          height: 210, // 高度
          text: this.qrcode, // 二维码内容
          background: "#f0f",
        })
      },
      goBack(){
        this.$router.go(-1)
      }
    },

  }

</script>

<style lang="less" scoped>
.wx-pay {
  margin: 0;
  padding: 0;
  .w-container {
    background: #fff;
    padding: 20px;
    .w-hd {
      display: flex;
      justify-content: space-between;

      .h-title {
        font-size: 16px;
        font-weight: 600;
      }

      .h-money {
        font-size: 14px;
        color: #666;

        strong {
          color: #ff7125;
          font-size: 22px;
          font-weight: 700;
        }
      }
    }

    .w-bd {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0px;
      box-sizing: border-box;
      border: 2px solid #ebeef5;
      padding: 20px 0px;

      .b-qr {
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .q-img {
          width: 210px;
          height: 210px;
          box-sizing: border-box;
        }

        .q-txt {
          width: 210px;
          height: 72px;
          margin-top: 20px;
          background: #e1251b;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            font-size: 40px;
            color: #fff;
          }
        }

        .q-content {
          font-size: 14px;
          color: #fff;
        }
      }

      .b-img {
        width: 262px;
        height: 400px;
        margin-left: 150px;

        img {
          width: 262px;
          height: 400px;
        }
      }
    }

    .w-ft {
      font-size: 16px;
      cursor: pointer;
      color: @primaryColor;
    }
    .b-txt {
      margin-left: 5px;
      padding: 2px 0px;
      border-bottom: 1px solid #e1251b;
    }
  }
}
</style>
